import React from 'react';
import { Container, Button } from 'react-bootstrap';

const Orientazione = () => (
    <Container className="text-center m-3">
    <h2>Scopri il percorso di laurea ideale per te</h2>
    <p>Usa lo strumento OrientAzione per capire quale corso è più adatto alle tue capacità e passioni.</p>
    <iframe
            src="https://www.orientazione.it"
            width="100%"
            height="300px"
            title="OrientAzione"
            className="border-0"
    ></iframe>
    <Button href="https://portale.orientazione.it/user/login" className="" variant="primary">Fai il test!</Button>

  </Container>
);

export default Orientazione;