import React from 'react';
import { Container } from 'react-bootstrap';
import { Row , Col} from 'react-bootstrap';
import { motion } from "motion/react"
import { FaArrowDown } from 'react-icons/fa';

const Home = () => (
    <Container className="text-center">
        <h1>Scopri il tuo futuro nell'Ingegneria</h1>
    <p>Guarda il nostro video introduttivo per capire cos'è l'ingegneria!</p>
    <Row>
    <Col md={3}></Col>
    <Col md={6}>
    <div class="ratio ratio-16x9">
        <iframe src="https://www.youtube-nocookie.com/embed/j3ctRmmHH7E?si=1-pyFmFZOT-vmmHc" allowfullscreen></iframe>
    </div>
    </Col>
    <Col md={3}></Col>
    </Row>
    <motion.div
            animate={{ y: [0, 20, 0] }}
            transition={{ repeat: Infinity, duration: 1 }}
            initial={{ opacity: 1 }}
            whileInView={{ opacity: 0 }}
            style={{ marginTop: '20px' }}
        >
        <a href='#orientazione'><FaArrowDown size={30}/></a>
    </motion.div>
  </Container>
);

export default Home;