import React from 'react';
import './App.css';
import Home from './components/Home';
import Orientazione from './components/Orientazione';
import Universita from './components/Universita';
import CISIA from './components/CISIA';
import Footer from './components/Footer';
import { useEffect, useState } from 'react';
import { Container, Navbar, Nav } from 'react-bootstrap';


function App() {
  const [activeSection, setActiveSection] = useState('home');

  useEffect(() => {
    const sections = document.querySelectorAll('section');
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
          }
        });
      },
      { threshold: 0.7 } // Considera una sezione attiva quando il 70% è visibile
    );

    sections.forEach((section) => observer.observe(section));
    return () => observer.disconnect();
  }, []);
  return (
  <div>
    <Navbar bg="primary" variant="dark" expand="md" fixed="top">
      <Container>
        <Navbar.Brand href="/"> <img src="/assets/logo-bg.png" width="90" height="30" className="m-2" alt=""></img></Navbar.Brand>
        
        <Navbar.Toggle aria-controls="navbar-nav" />
        <Navbar.Collapse id="navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="#home" className={activeSection === 'home' ? 'active' : ''}>
              Cos'è l'ingegneria?
            </Nav.Link>
            <Nav.Link href="#orientazione" className={activeSection === 'orientazione' ? 'active' : ''}>
              OrientAzione
            </Nav.Link>
            <Nav.Link href="#universita" className={activeSection === 'universita' ? 'active' : ''}>
              Università
            </Nav.Link>
            <Nav.Link href="#cisia" className={activeSection === 'cisia' ? 'active' : ''}>
              Test CISIA
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    {/* Sections */}
    <div className="snap-container">
      <section id="home">
        <Home />
      </section>
      <section id="orientazione">
        <Orientazione />
      </section>
      <section id="universita">
        <Universita />
      </section>
      <section id="cisia">
        <CISIA />
      </section>
      <Footer />
      
      
    </div>
  </div>
  );
}

export default App;
