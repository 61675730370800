import React from 'react';
import { Container, Button, Row } from 'react-bootstrap';
import Footer from './Footer';

const Cisia = () => (
  <Container id="cisia" className="text-center">
    <h2>Preparati al Test CISIA</h2>
    <p>Scopri quanto è semplice superare il test di ingresso con le nostre risorse.</p>
    <Button href="/slides/cisia.pdf" variant="warning">Guarda le slide</Button>
  </Container>
  
);

export default Cisia;