import React from 'react';
import { Container, Navbar, Nav, Row } from 'react-bootstrap';

const Footer = ({  }) => (
  <>

    <footer className="footer fixed-bottom bg-primary py-3">
        <Container className='text-center text-white'>
            <Row>
            © 2024 Dipartimento di Ingegneria
            </Row>

        </Container>
    </footer>
    
  </>
);

export default Footer;