import React from 'react';
import { Container, Card, Row, Col } from 'react-bootstrap';

const Universita = () => (
  <Container id="universita" className="text-center mt-3">
    <h2>Benvenuti nella nostra Università</h2>
    <p>Esplora i nostri corsi, i laboratori e la gara dei car robot!</p>
    <Row className="mt-4">
      <Col md={6}>
        <Card>
          <Card.Body>
            <Card.Title>La nostra offerta formativa</Card.Title>
            <Card.Text>
              Scopri i nostri corsi e le opportunità per gli studenti.
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
      <Col md={6}>
        <Card>
          <Card.Body>
            <Card.Title>La gara dei car robot</Card.Title>
            <Card.Text>
              Quattro scuole, un professore e una sfida per creare il miglior robot!
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  </Container>
);

export default Universita;